<template>

<!-- nav breadcrumb -->
<nav style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb" id="breadcrumb_container">
<ol class="breadcrumb">
    <li class="breadcrumb-item" aria-current="page"><router-link :to="{name: 'ChangePassword'}">My Account</router-link></li>
    <li class="breadcrumb-item"><a href="/orders/cart">Cart</a></li>
    <li class="breadcrumb-item active">Check Out</li>
</ol>
</nav>

<div id="maincontent_container">
    <div v-if="error">
        <div class="alert alert-danger">{{error}}</div>
    </div>
    <div v-if="message">
        <div v-if="message == 'PO has been placed successfully.'" class="alert alert-success alert-dismissible fade show" role="alert">
            Web P.O. has been placed successfully. Your account manager will contact you shortly. <a href="/account/web-order">Click here</a> to view your Web P.O. records from the past 30 days.
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
        <div v-else  class="alert alert-success alert-dismissible fade show" role="alert">
            {{message}}
             <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
    </div>
    <div v-else>
        <div >
            <h2 class="mb-4 mt-2">Select a Shipping Address</h2>
            <div>
            <form @submit.prevent="handleSubmit">
                <div class="form-check">
                <input class="form-check-input" type="radio" @click="handleClick($event)" name="flexRadioDefault" id="flexRadioDefault1" value=1 checked/>
                <label class="form-check-label" for="flexRadioDefault1">
                    Use my default shipping address.
                </label>

                <br /><br />

                <div>
                    <p>
                    {{defaultAddress.company}}
                    <br />
                    {{defaultAddress.addr1}}
                    {{defaultAddress.addr2}}
                    <br />
                    {{defaultAddress.city}} {{defaultAddress.state}} {{defaultAddress.zip}}
                    <br />
                    Phone: {{defaultAddress.phone}}
                    </p>
                </div>
                </div>

                <div class="form-check">
                <input class="form-check-input" type="radio" @click="handleClick($event)" name="flexRadioDefault" id="flexRadioDefault2" value=0 />
                <label class="form-check-label" for="flexRadioDefault2">
                    Enter a different shipping address.
                </label>

                <div>
                    <div class="row g-3">
                    <div class="col-sm-2">
                        <label for="company" class="col-form-label"
                        >Company/Name:</label
                        >
                    </div>
                    <div class="col-sm-5">
                        <input type="text" v-model="company" id="inputCompany" class="form-control" :required ="requiredInput" maxlength="35"/>
                    </div>
                    </div>
                    <br>
                    <div class="row g-3">
                    <div class="col-sm-2">
                        <label for="Address1" class="col-form-label"
                        >Address Line 1:</label
                        >
                    </div>
                    <div class="col-sm-5">
                        <input type="text" v-model="addr1" id="inputAddress1" class="form-control" :required ="requiredInput" maxlength="35"/>
                    </div>
                    </div>
                    <br>
                    <div class="row g-3">
                    <div class="col-sm-2">
                        <label for="Address2" class="col-form-label"
                        >Address Line 2:(Optional)</label
                        >
                    </div>
                    <div class="col-sm-5">
                        <input type="text" v-model="addr2" id="inputAddress2" class="form-control" maxlength="35" />
                    </div>
                    </div>
                    <br>
                    <div class="row g-3">
                    <div class="col-sm-2">
                        <label for="Country" class="col-form-label">Country:</label>
                    </div>
                    <div class="col-sm-5">
                        <select v-model="country" class="form-select" aria-label="Default select example" id="country" name="country" @change="onChange($event)" :required ="requiredInput">
                            <option selected>Select a Country</option>                      
                            <option v-for="country in countryList" :key="country" :value="country.iso" >{{country.printable_name}}</option>
                        </select>
                    </div>
                    </div>
                    <br>
                    <div class="row g-3">
                    <div class="col-sm-2">
                        <label for="City" class="col-form-label">City:</label>
                    </div>
                    <div class="col-sm-5">
                        <input type="text" v-model="city" id="inputCity" class="form-control" :required ="requiredInput" maxlength="33" />
                    </div>
                    </div>
                    <br>
                    <div class="row g-3">
                    <div class="col-sm-2">
                        <label for="State" class="col-form-label">State:</label>
                    </div>
                    <div class="col-sm-5">
                            <select v-model="state" class="form-select"  aria-label="Default select example" id="state" name="state" :disabled="disabledState" :required="requiredState">
                                <option selected>Select a State</option>                      
                                <option v-for="state in stateList" :key="state" :value="state.abbrev">{{state.name}}</option>
                            </select>
                    </div>
                    </div>
                    <br>
                    <div class="row g-3">
                    <div class="col-sm-2">
                        <label for="Zip" class="col-form-label">Zipcode:</label>
                    </div>
                    <div class="col-sm-5">
                        <input type="text" v-model="zip" id="inputZip" class="form-control" :required ="requiredInput" maxlength="10" />
                    </div>
                    </div>
                    <br>
                    <div class="row g-3">
                    <div class="col-sm-2">
                        <label for="Phone" class="col-form-label">Phone:</label>
                    </div>
                    <div class="col-sm-5">
                        <input type="text" v-model="phone" id="inputPhone" class="form-control" :required ="requiredInput" maxlength="17"/>
                    </div>
                    </div>
                </div>
                </div>

                <br /><br /><br />

                <h2 class="mb-4">Delivery Preference</h2>
                <div class="row g-3">
                    <label class="col-sm-2 control-label">Shipping Method:</label>
                    <div class="col-sm-5">
                        <div class="wrapper" >
                        <select size="6" v-model="shipCode" name="shippingMethod" id="shippingMethod" class="form-control" required>
                            <option  v-for="v in shippingList" :key="v" :value=v[1]>{{v[0]}}</option>
                        </select>
                        </div>
                    </div>
                </div>

                <br /><br /><br />

                <h2 class="mb-4">Other Information (Optional)</h2>
                <div>
                <div class="row g-3">
                    <div class="col-sm-2">
                    <label for="ponumber" class="col-form-label">PO Number:</label>
                    </div>
                    <div class="col-sm-5">
                    <input type="text" v-model="POnumber" id="inputPo" class="form-control" />
                    </div>
                </div>
                <br />
                <div class="row g-3">
                    <div class="col-sm-2">
                    <label for="ponumber" class="col-form-label"> </label>
                    </div>
                    <div class="col-sm-5">
                    <p>
                        Important: For alternative ship to address, please type in new
                        address at the above Enter a difference shipping address, to
                        make sure your order being shipped to the correct address and
                        not causing any delay on processing.
                    </p>
                    </div>
                </div>
                <div class="row g-3">
                    <div class="col-sm-2">
                    <label for="ponumber" class="col-form-label">Special Instructions:</label>
                    </div>
                    <div class="col-sm-5">
                    <textarea id="inputdes" v-model="notes" name="inputdes" rows="6" cols="65"></textarea>
                    </div>
                </div>
                </div>
                <p class="text-warning bold">
                Important Note: Please make sure the address is entered correctly.
                Otherwise the shipping cost may be calculated inaccurately and your
                package may be returned as undeliverable.
                </p>
                <button v-if="checkout_spinner" class="btn btn-primary btn-lg" id="cart_btn" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Submit
                </button>
                <button v-if="!checkout_spinner" class="btn btn-primary btn-lg" id="cart_btn">
                    Submit
                </button>
                <!-- <button class="btn btn-primary">Submit</button> -->
            </form>
            
            <br><br><br>
            <p>
                <u>Price and Availability</u><br />
                Please check with your account manager for final pricing and product
                availability (subject to change without prior notice). Unless otherwise
                indicated, all price quotes are based on a cash discount offered to all
                buyers for payment by cash or check as permitted by law. Some Brands may
                not be eligible for sale outside the U.S. Please review with your
                account manager. All logistics charges, fees, costs, and taxes are
                subject to change based on final destination.
                <br /><br />
            </p>
            <p>
                <u>Terms &amp; Conditions</u><br />
                The Terms and Conditions of Sale at
                <router-link class="blue_link" :to="{ name: 'TermsAndConditions' }"
                >www.malabs.com/terms-conditions</router-link>
                > apply to all transactions. <br /><br />
            </p>
            <p>
                <u>Destination Control Statement</u><br />
                Diversion contrary to US export laws of goods or services purchased from
                Ma Labs is strictly prohibited.
                <br /><br />
            </p>
            </div>
        </div>

    </div>

</div>
</template>


<script>
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { ref } from 'vue'
import $ from 'jquery'

export default {
    name: "Shipping",
    setup (props, context) {
        const router = useRouter()
        const store = useStore()

        const error = ref('')
        const message = ref('')
        const checkout_spinner = ref(false)
        const defaultAddress = ref(null)
        const countryList = ref(null)
        const stateList = ref(null)
        const shippingMethods = ref(null)
        const shippingList = ref([])
        const disabledState = ref(Boolean)
        const requiredInput = ref(false)
        const requiredState = ref(false)
        const payload = ref({})
        const radioValue = ref(1)

        const company = ref('')
        const addr1 = ref('')
        const addr2 = ref('')
        const country = ref('')
        const city = ref('')
        const state = ref('')
        const zip = ref('')
        const phone = ref('')
        const POnumber = ref('')
        const shipCode = ref('')
        const notes = ref('')


        defaultAddress.value = store.getters.defaultAddress
        countryList.value = store.getters.countryList
        stateList.value = store.getters.stateList
        shippingMethods.value = store.getters.shippingMethods
        //console.log(store.getters.shippingMethods)

        updatePayload()

        for (let method in shippingMethods.value){
            shippingList.value.push([shippingMethods.value[method],method])          
        }
        // console.log(shippingList.value,"test123")

        if (countryList.value.length == 0){
            router.push({ name: "Cart"});
        }

        function handleClick(event){
            radioValue.value = event.target.value
            updatePayload()
        }
        
        function updatePayload() {
            if(radioValue.value == 1){
                requiredInput.value = false
                payload.value["shipping_to_name"] = defaultAddress.value.company
                payload.value["shipping_address1"] = defaultAddress.value.addr1
                payload.value["shipping_address2"] = defaultAddress.value.addr2
                payload.value["shipping_city"] = defaultAddress.value.city
                payload.value["shipping_state"] = defaultAddress.value.state
                payload.value["shipping_zip"] = defaultAddress.value.zip
                payload.value["shipping_country"] = defaultAddress.value.country
                payload.value["customer_phone"] = defaultAddress.value.phone
            }
            else if(radioValue.value == 0) {
                requiredInput.value = true
                payload.value["shipping_to_name"] = company.value
                payload.value["shipping_address1"] = addr1.value
                payload.value["shipping_address2"] = addr2.value
                payload.value["shipping_city"] = city.value
                payload.value["shipping_state"] = state.value
                payload.value["shipping_zip"] = zip.value
                payload.value["shipping_country"] = country.value
                payload.value["customer_phone"] = phone.value
            }
            payload.value["custom_note"] = notes.value
            payload.value["custom_po"] = POnumber.value
            payload.value["shipping_code"] = shipCode.value
            payload.value["use_default_ship_to"] = parseInt(radioValue.value)
        }  
        
        function onChange(event){
            let country = event.target.value
            if (country === "US"){
                disabledState.value = false;
                requiredState.value = true
            }
            else{
                disabledState.value = true;
                requiredState.value = false
            }
        }
        const handleSubmit = async () =>{
            updatePayload()
            error.value = ""
            checkout_spinner.value = true

            let newPayload = ''
            for (let item in payload.value) {
                newPayload += encodeURIComponent(item) + '=' + encodeURIComponent(payload.value[item]) + '&'
                
            }
            // console.log(payload.value,"test555")
            try{
                let result = await store.dispatch('submitPo', newPayload)
                checkout_spinner.value = false
                message.value = result.data.message
                $('html, body').animate({scrollTop: '0px'}, 100)

                //log access (save this user action into log system)
                try {
                    if (store.getters.isCustomer) {
                        let payload = {
                            'action':'submit po',
                            'po_id': result["data"]["data"]["po_id"],
                        }
                        let result2 = await store.dispatch('logAccess', payload)
                    }
                }
                catch {
                //do nothing when error happends
                }

                //update cart quantity page on header
                context.emit("updateCartQty");
            }
            catch(err){
                try {
                    checkout_spinner.value = false
                    error.value = err.response.statusText
                }
                catch(err) {
                    error.value = "Something is wrong."
                }
                window.scrollTo({ top: 0, behavior: 'smooth' });

            }

        }

        return{
            defaultAddress,
            countryList,
            stateList,
            shippingMethods,
            shippingList,
            onChange,
            handleClick,
            disabledState,
            requiredInput,
            requiredState,
            handleSubmit,
            company,
            addr1,
            addr2,
            country,
            city,
            state,
            zip,
            phone,
            shipCode,
            POnumber,
            notes,
            error,
            message,
            checkout_spinner,
        }


    }
    
}
</script>